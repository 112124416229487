import styles2 from "../css/institucional.module.css";

import React, { useEffect } from 'react';

export const VisitorCounter = () => {
  useEffect(() => {



    sessionStorage.setItem('visit', 'x');
    // 'visit' item persists in storage for the remainder of the user session


    // Dynamically add the script to the specified div
    const script = document.createElement('script');
    script.src =
      'https://counter6.optistats.ovh/private/counter.js?c=tb937xqahue8kb7agtrlhkb8rc1k3k3x&down=async';
    script.type = 'text/javascript';
    script.async = true;

    // Append the script to the div with the specific ID
    document
      .getElementById('sfctb937xqahue8kb7agtrlhkb8rc1k3k3x')
      .appendChild(script);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  return (
    <div class={styles2.col_lg_12} align = "center">Visitantes:
      {/* Target div where the counter will render */}
      <div id="sfctb937xqahue8kb7agtrlhkb8rc1k3k3x"></div>
      <br />
      {/* Fallback content for browsers that don't support JavaScript */}
      <noscript>
        <a
          href="https://www.contadorvisitasgratis.com"
          title="contador de visitas para blog"
        > 
          <img
            src="https://counter6.optistats.ovh/private/contadorvisitasgratis.php?c=tb937xqahue8kb7agtrlhkb8rc1k3k3x"
            border="0"
            title="contador de visitas para blog"
            alt="contador de visitas para blog"
          />
        </a>
      </noscript>
    </div>
    
    
  );
};


