
import styles from "../../css/institucional.module.css";
import d1 from "../../images/Espectros/Espectros.png";
import orcid from "../../images/orcid-icon.png";


export const Espectros = () => {
  return (
    <>
	<h1>&nbsp;</h1>
	  <div class="col-md-2"></div>
      <div class="col-md-8">
      <div align="right">Published Online: October, 2024</div>
<h1 title="Dataset of induced fluorescence spectra in human hepatic biopsies">Dataset of induced fluorescence spectra in human hepatic biopsies</h1>
<h6 title="Authors">Jesús Yaljá Montiel Pérez <a href="https://orcid.org/0000-0002-0214-1080" target="new" alt="ORCID Icon"><img src={orcid} width="15" alt="OrcID Icon"/></a>, 
César Castrejón Peralta <a href="https://orcid.org/0009-0009-4653-9742" target="new" alt="ORCID Icon"><img src={orcid} width="15"  alt="OrcID Icon"/></a>,
Jonathan Axel Cruz Vázquez <a href="https://orcid.org/0000-0003-4222-0573" target="new" alt="ORCID Icon"><img src={orcid} width="15"  alt="OrcID Icon"/></a>,
José Manuel de la Rosa Vázquez <a href="https://orcid.org/0000-0002-7781-8906" target="new" alt="ORCID Icon"><img src={orcid} width="15"  alt="OrcID Icon"/></a>,  
Diego Adrian Fabila Bustos <a href="https://orcid.org/0000-0002-6383-1675" target="new" alt="ORCID Icon"><img src={orcid} width="15"  alt="OrcID Icon"/></a>, 
Karen Roa Tort <a href="https://orcid.org/0000-0002-6780-6370" target="new" alt="ORCID Icon"><img src={orcid} width="15"  alt="OrcID Icon"/></a>, 
Josué Daniel Rivera Fernández <a href="https://orcid.org/0000-0003-2967-6736" target="new" alt="ORCID Icon"><img src={orcid} width="15"  alt="OrcID Icon"/></a></h6>
<div></div>
<h3>Abstract</h3>
<div width="80%" title="Abstract">Under the premise of literature that identifies the molecules comprising the liver and their modifications in disease states, the stages of liver biopsies can be marked by induced fluorescence intensities related to their composition. This article aims to present a statistical analysis of induced fluorescence spectra from 401 liver biopsies associated with four disease stages, previously cataloged by pathologists using the METAVIR score system. The spectra is provided as a database containing 7,551 intensity measurements. To induce fluorescence in liver tissue, three illumination sources with wavelengths of 330 nm, 365 nm, and 405 nm were used, chosen as these wavelengths are close to the excitation points of the principal components necessary to characterize liver disease stages.</div>
	
<figure align="center"><a href={'/Files/espectros.svg'} target="new"><img src={d1} alt="Espectros" width="80%"/></a><figcaption>Figure 1. Normalized fluorescence spectra of hepatic biopsies</figcaption></figure>


  <h3>Acknowledgments</h3>
	<div title="Acknowledgements"><p>This work was funded by the <strong>Instituto Politécnico Nacional</strong> under the framework of <strong><em>Proyectos de Desarrollo Tecnológico o Innovación 2024, Sistema inteligente de clasificación de estados de enfermedad en biopsias hepáticas</em></strong>, with registration <strong>SIP 20242778</strong>. 
	</p></div>
<div><p>The induced fluorescence spectra data were obtained in the <strong>Laboratorio de Fotónica of the Escuela Superior de Ingeniería Mecánica y Eléctrica </strong> at the <strong>Instituto Politécnico Nacional</strong>.</p></div>
	<div><p>Liver tissue biopsies were provided by the <strong>Liver, Pancreas and Motility Laboratory, Experimental Medicine Unit, Faculty of Medicine, National Autonomous University of Mexico</strong>/<strong>General Hospital of Mexico “Dr. Eduardo Liceaga”</strong>.</p></div>

	<div>&nbsp;</div>
	<h3>INDAUTOR Records</h3>
	<div title="INDAUTOR Records">03-2024-050810212500-01</div>
	<div title="INDAUTOR Records">03-2024-081609255000-01</div>
	<div title="INDAUTOR Records">03-2024-XXX</div>
	
	<h3>Links</h3>
<div>
	<p title="Downloable Files"> The data of the spectra are organized by illumination source: 330 nm, 365 nm, and 406 nm. For each illumination source, there are 4 files with spectra cataloged at METAVIR score system F0, F1-F2, F3, and F4. In total, there are 12 text files that correspond to the graphs shown in Figure 1. </p>
  <p>&nbsp;</p>
</div>
	<div align="center">
	<table width="80%" border="1" title="Downloable Files">
	  <tbody>
	    <tr align="center">
	      <th scope="col" align="center">&nbsp;Ilumination source<br/>
	        <br/>METAVIR score</th>
        <th scope="col">330 nm<br></br>&nbsp;<br></br>&nbsp;</th>
	      <th scope="col">365 nm<br></br>&nbsp;<br></br>&nbsp;</th>
	      <th scope="col">406 nm<br></br>&nbsp;<br></br>&nbsp;</th>
        </tr>
	    <tr>
	      <th scope="row">&nbsp;F0</th>
	      <td>&nbsp;<a href={'/Files/330/F0.txt'} target="new">Illumination source at 330 nm for F0</a></td>
	      <td>&nbsp;<a href={'/Files/365/F0.txt'} target="new">Illumination source at 365 nm for F0</a></td>
	      <td>&nbsp;<a href={'/Files/405/F0.txt'} target="new">Illumination source at 405 nm for F0</a></td>
        </tr>
	    <tr>
	      <th scope="row">&nbsp;F1-F2</th>
	      <td>&nbsp;<a href={'/Files/330/F1-F2.txt'} target="new">Illumination source at 330 nm for F1-F2</a></td>
	      <td>&nbsp;<a href={'/Files/365/F1-F2.txt'} target="new">Illumination source at 365 nm for F1-F2</a></td>
	      <td>&nbsp;<a href={'/Files/405/F1-F2.txt'} target="new">Illumination source at 405 nm for F1-F2</a></td>
        </tr>
	    <tr>
	      <th scope="row">&nbsp;F3</th>
	      <td>&nbsp;<a href={'/Files/330/F3.txt'} target="new">Illumination source at 330 nm for F3</a></td>
	      <td>&nbsp;<a href={'/Files/365/F3.txt'} target="new">Illumination source at 365 nm for F3</a></td>
	      <td>&nbsp;<a href={'/Files/405/F3.txt'} target="new">Illumination source at 405 nm for F3</a></td>
        </tr>
		  <tr>
	      <th scope="row">&nbsp;F4</th>
	      <td>&nbsp;<a href={'/Files/330/F4.txt'} target="new">Illumination source at 330 nm for F4</a></td>
	      <td>&nbsp;<a href={'/Files/365/F4.txt'} target="new">Illumination source at 365 nm for F4</a></td>
	      <td>&nbsp;<a href={'/Files/405/F4.txt'} target="new">Illumination source at 405 nm for F4</a></td>
        </tr>
      </tbody>
</table>
<div align="right"><a href={'/Files/espectros.zip'} target="new">Download all files</a></div>
<div>&nbsp;</div>
</div>
	<h3>Cite this articule</h3>
<div >This dataset is for academic and research use and should be cited as:<br></br>
<h6>Jesús Yaljá Montiel Pérez, César Castrejón Peralta, Jonathan Axel Cruz Vázquez, José Manuel de la Rosa Vázquez, Diego Adrian Fabila Bustos, Karen Roa Tort, Josué Daniel Rivera Fernández; Dataset of induced fluorescence spectra in human hepatic biopsies; XXXX; 2024.</h6></div>
	<h4>More Information</h4>
	   <div>
         <a href="mailto:yalja@ipn.mx">yalja@ipn.mx</a> <br/>
         <a href="mailto:mdelaros@ipn.mx">mdelaros@ipn.mx</a></div>

      </div>
      
    </>
    
  );
}