import { Route, Routes, useLocation } from 'react-router-dom';
import { ListLinksMenu } from "./templates/NavBar";
import { Footer } from "./templates/Footer";
import { HomePage } from './pages/HomePage';
import { Alumnos } from './pages/Alumnos';
import { Proyectos } from './pages/Proyectos';
import { Tesis } from './pages/Tesis';
import { Estancias } from './pages/Estancias';
import { Delfin } from './pages/Delfin'
import { PDF } from './pages/PDF';
import styles from "./css/NavBar.module.css";
import './App.css';
import Galeria from './pages/Galeria';
import {Investigacion} from './pages/Investigacion';
import { Qiskit2023 } from './pages/Qiskit2023';
import {Espectros} from './pages/BD/Espectros'
import { Footer_ipn } from './templates/Footer_ipn';
import {Social} from './templates/Social';
import {Menu_Lab} from './templates/Menu_Lab';
import {Calendario} from './pages/Calendario';
import {Yalja} from './pages/Yalja';
import {JorgeE} from './pages/JorgeE';
import {HumbertoS} from './pages/HumbertoS.js';
import {ErikZ} from './pages/ErikZ.js';
import {ElsaR} from './pages/ElsaR.js';
import {Infraestructura} from './pages/Infraestructura.js';
import { EIR2024 } from './pages/EIR2024.js';
import {Titular} from './templates/Titular.jsx'

//const About = ({ nombre }) => <h6 style={{ textAlign: 'center', marginTop: 500 }}>{nombre}</h6>;

function App() {
  const location = useLocation();
  ///////////////////////////////////////////////////////////////////////////////////////

  

  ///////////////////////////////////////////////////////////////////////////////////////
  return (
    
    <div>
      <Menu_Lab />
      {/*location.pathname !== '/' && location.pathname !== '/programa-delfin' && <header className={styles.navbar}><ListLinksMenu subprincipal={true} styles={styles} /></header>*/}
      <Routes>
        <Route path='/' element={<HomePage />} />
        {/*<Route path='/seminario' element={<About nombre='Página seminario' />} />
        <Route path='/reconocimientos' element={<About nombre='Página reconocimientos' />} />*/}
        <Route path='/proyectos' element={<Proyectos />} />
        <Route path='/estancias' element={<Estancias />} />
        <Route path='/tesis' element={<Tesis />} />
        <Route path='/alumnos25A' element={<Alumnos />} />
        <Route path='/alumnos24B' element={<Alumnos />} />
        <Route path='/alumnos24A' element={<Alumnos />} />
        <Route path='/alumnos23B' element={<Alumnos />} />
        <Route path='/alumnos23A' element={<Alumnos />} />
        <Route path='/alumnos/:PDF' element={<PDF />} />
        {/*<Route path='/publicaciones' element={<About nombre='Página publicaciones' />} />*/}
        <Route path='/programa-delfin' element={<Delfin />} />
        <Route path='/galeria' element={<Galeria />} />
        <Route path='/Investigacion' element={<Investigacion />} />
        <Route path='/Qiskit2023' element={<Qiskit2023 />} />
        <Route path='/EIR2024' element={< EIR2024/> }/>
        <Route path='/Espectros' element={<Espectros />} />
        <Route path='/Calendario' element={<Calendario />}/>
        <Route path='/Yalja' element={<Yalja />}/>
        <Route path='/JorgeE' element={<JorgeE />}/>
        <Route path='/HumbertoS' element={<HumbertoS />}/>
        <Route path='/ErikZ' element={<ErikZ />} />
        <Route path='/ElsaR' element={<ElsaR />} />
        <Route path='/Infraestructura' element={<Infraestructura />} />
        <Route path='*' element={<h1>Not Found</h1>} />
      </Routes>
      {/*<VisitorCounter />*/}
      <Social />
      
      <Footer />
      <Footer_ipn/>
    </div>

  );
}

export default App;
