import foto from "../images/Personal_JorgeE/JorgeE_Profile_Pic.jpg"

import pr_hybrid from "../images/Personal_JorgeE/hybrid_md.jpg";
import pr_graph from "../images/Personal_JorgeE/res_graf1.png";
import pr_IoT from "../images/Personal_JorgeE/IoT_sec1.jpg";
import pr_PDR from "../images/Personal_JorgeE/PDR.jpg";
import pr_Fo_Sensor from "../images/Personal_JorgeE/fo_sensor.png";
import pr_Viewnet from "../images/Personal_JorgeE/Viewnet3.png";
import pr_WISD from "../images/Personal_JorgeE/WISD1.png";
import pr_Autotaxi from "../images/Personal_JorgeE/Autotaxi1.png";


export const JorgeE = () => {
    return (
      <>
        <h1 onLoad={window.scrollTo(0, 0)}>&nbsp;</h1>
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
            <h3>Dr. P. Jorge Escamilla-Ambrosio</h3>
            <hr class="red"/>
            <p align="right"><a href="https://www.cic.ipn.mx/~pescamilla/index.html" target="new">Página Personal</a></p>
            <div className="col-lg-4"><img src={foto} width="100%" alt="Foto Dr. Jorge Escamilla"></img></div>
          <div className="col-lg-8">
          <p><strong>Formación Académica</strong></p>
            <p><ul>
                <ol>Doctor of Phylosophy (Automatic Control and Systems Engineering)</ol>
                <ol>M. en Ingeniería Eléctrica</ol>
                <ol>Ing. Mecánico Electricista </ol>
              </ul></p>
              <p><strong>Unidades de Aprendizaje</strong></p>
              <p><ul>
                <ol>Probability-Random-Processes-and-Inference</ol>
                <ol>Control Inteligente</ol>
                <ol>Seguridad en Dispositivos Móviles</ol>
                <ol>Internet de las Cosas</ol>
                <ol>Ciberseguridad</ol>
                <ol>Fusión de Sensores</ol>
              </ul></p>
              <p><strong>Líneas de investigación y desarrollo</strong></p>
              <p><ul>
                <li>Integración/fusión de información/sensores y aplicaciones</li>
                <li>Redes de sensores inalámbricos</li>
                <li>Internet de las cosas</li>
                <li>Filtrado de Kalman</li>
                <li>Monitoreo de la salud estructural</li>
                <li>Sistemas de Control Difuso</li>
                <li>Redes Neuronales Artificiales</li>
                <li>Sistemas Neuro-Difusos </li>
                <li>Controles inteligentes/adaptativos</li>
              </ul></p>
          </div>
          <h3>Investigaciones previas</h3>
          <hr class="red"/>
        <div className="col-lg-12">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
                <div className="col-lg-8"><figure><img src={pr_hybrid} width="100%" alt="Proyecto Hybrid Android Malware Detection Methods"/></figure></div>
                <div className="col-lg-4"><p>2-Hybrid Android Malware Detection Methods, Participantes: Dr. Ponciano Jorge Escamilla-Ambrosio, Dr. Abraham Rodríguez-Mota, Dra. Lilian Coronado-De-Alba. <br /> El objetivo de esta investigación es diseñar y desarrollar una estructura de detección de malware híbrida, que explorará la integración y gestión de diferentes métodos de detección y análisis de malware. El término híbrido implica tanto una implementación local (host)-remota (servidor/nube) como un enfoque de análisis estático-dinámico.</p></div>
            </div>
            <div className="col-lg-1"></div>
        </div>
        <p>&nbsp;</p>
        <div className="col-lg-12">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
                <div className="col-lg-6"><figure><img src={pr_graph} width="100%" alt="Proyecto Monitoring non authorized remote access to private information"/></figure></div>
                <div className="col-lg-6"><p>Monitoring non authorized remote access to private information, CONACYT grant. Participants: Dr. Ponciano Jorge Escamilla-Ambrosio, Dr. Abraham Rodríguez-Mota, Dra. Lilian D. Coronado-De-Alba, Dr. Eleazar Aguirre-Anaya, Dr. Moisés Salinas-Rosales, Dr. Raúl Acosta-Bermejo; Master's students: Salvador Morales-Ortega, Cindy G. Hernández-Acosta, Gerardo Tinajero-Manjarrez. <br /> Este proyecto pretende realizar una investigación científica aplicada orientada al desarrollo de herramientas de monitorización de comportamientos anómalos y detección de software malicioso, incluidos aquellos que tienen acceso a información privada almacenada en dispositivos móviles sin la autorización del propietario.</p></div>
            </div>
            &nbsp;
            <div className="col-lg-1"></div>
        </div>
        <p>&nbsp;</p>
        <div className="col-lg-12">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
                <div className="col-lg-4"><figure><img src={pr_IoT} width="100%" alt="Proyecto IoT"/></figure></div>
                <div className="col-lg-8"><p>Estoy realizando investigaciones en el área de Internet de las cosas (IoT) y sus aplicaciones, incluyendo temas de seguridad. <br /> Entre las diferentes aplicaciones que pueden beneficiarse de esta tecnología se incluyen: monitorización de vehículos, activos, personas y mascotas; automatización agrícola; consumo energético; seguridad y vigilancia; gestión y automatización de edificios; monitorización y gestión energética; hogares y ciudades inteligentes; telemedicina y atención sanitaria; entre otras. </p></div>
            </div>
            &nbsp;
            <div className="col-lg-1"></div>
        </div>  
        <p>&nbsp;</p>
        <div className="col-lg-12">
            <div className="col-lg-1">A</div>
            <div className="col-lg-10">
                <div className="col-lg-4"><figure><img src={pr_PDR} width="100%" alt="Proyecto sensores inerciales y redes de sensores inalámbricos (WSN)"/></figure></div>
                <div className="col-lg-8"><p>También estoy investigando en el área de navegación personal utilizando sensores inerciales y redes de sensores inalámbricos (WSN). <br />La navegación personal permitirá diferentes aplicaciones basadas en la ubicación. En entornos al aire libre, la solución la proporciona el Sistema de Posicionamiento Global (GPS). Sin embargo, en aplicaciones de interior o en cañones urbanos, el GPS no funciona. Por lo tanto, se están investigando soluciones alternativas para la navegación en interiores. La navegación por estima de peatones utilizando sensores inerciales y WSN es una alternativa que se está explorando. </p></div>
            </div>
            &nbsp;
            <div className="col-lg-1">F</div>
        </div>  
        <p>&nbsp;</p>
        <div className="col-lg-12">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
                <div className="col-lg-4"><figure><img src={pr_Fo_Sensor} width="100%" alt="Proyecto sensores de fibra óptica"/></figure></div>
                <div className="col-lg-8"><p>Estoy colaborando con investigadores del CIITEC-IPN en el desarrollo de sensores de fibra óptica (FO) para aplicaciones de monitoreo de salud estructural. <br />Las ventajas de los sensores de FO, con respecto a los sensores tradicionales, son que son inmunes a interferencias electromagnéticas, son más livianos y una sola fibra puede usarse como sensor distribuido. </p></div>
            </div>
            &nbsp;
            <div className="col-lg-1"></div>
        </div> 
        <p>&nbsp;</p>
        <div className="col-lg-12">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
                <div className="col-lg-4"><figure><img src={pr_WISD} width="100%" alt="Proyecto Dispositivos de detección inteligentes inalámbricos para el control de la salud"/></figure></div>
                <div className="col-lg-8"><p>Desde julio de 2005 hasta julio de 2008 participé en el proyecto de investigación colaborativa titulado "Dispositivos de detección inteligentes inalámbricos para el control de la salud", financiado por el Departamento de Comercio e Industria del Reino Unido (DTI), Proyecto DTI N.º TP/2/SC/6/I/10347. <br /> Los socios del proyecto fueron la Universidad de Bristol, Augusta Westland, TRW Conekt y Systems Engineering & Assessment Ltd (SEA). En el marco de este programa, fui responsable del trabajo de investigación en el área de extracción de características, reconocimiento de patrones y fusión de sensores para detectar y clasificar daños en estructuras de helicópteros (cabeza del rotor). <br />Propuse enfoques inteligentes para la extracción de características y el reconocimiento de patrones que combinaban la teoría de la transformada wavelet, la teoría de conjuntos difusos y la teoría del control estadístico de procesos. </p></div>
            </div>
            &nbsp;
            <div className="col-lg-1"></div>
        </div>  
        <p>&nbsp;</p>
        <div className="col-lg-12">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
                <div className="col-lg-4"><figure><img src={pr_Viewnet} width="100%" alt="Proyecto de investigación colaborativa llamado ViewNet"/></figure></div>
                <div className="col-lg-8"><p>Desde agosto de 2008 hasta marzo de 2010 fui investigador asociado en el Departamento de Ciencias Informáticas de la Universidad de Bristol. <br /> El foco de mi investigación allí fue el desarrollo de algoritmos de fusión de sensores para el proyecto de investigación colaborativa llamado ViewNet. El objetivo de este proyecto era la fusión de múltiples sensores, incluidos monovisión, GPS, sistema de posicionamiento UWB e INS para realizar la localización y el mapeo simultáneos (SLAM) de entornos interiores y exteriores no estructurados. </p></div>
            </div>
            &nbsp;
            <div className="col-lg-1"></div>
        </div> 
        <p>&nbsp;</p>
        <div className="col-lg-12">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
                <div className="col-lg-4"><figure><img src={pr_Autotaxi} width="100%" alt=" proyecto de investigación colaborativo titulado 'Autotaxi'"/></figure></div>
                <div className="col-lg-8"><p>Desde julio de 2003 hasta junio de 2005 participé en el proyecto de investigación colaborativo titulado 'Autotaxi'. El consorcio para desarrollar este proyecto estaba integrado por la Universidad de Bristol, Advanced Transportation Systems Ltd, TRW Conekt, Praxis Safety Critical Systems Ltd y la Universidad de Warwick. <br />El objetivo del proyecto Autotaxi era desarrollar un sistema de sensores críticos para la seguridad que realizara la detección requerida por los pequeños vehículos eléctricos autónomos, conocidos como vehículos de transporte ligero urbano (ULTra), para circular de forma segura en redes de vías pavimentadas exclusivas. Como co-investigador en este proyecto, desarrollé los algoritmos de fusión de datos de múltiples sensores implementados en el sistema Autotaxi. </p></div>
            </div>
            &nbsp;
            <div className="col-lg-1"></div>
        </div>  
        </div>
        <div className="col-lg-1"></div>
      </>
  );
};