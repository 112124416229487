import styles2 from "../css/institucional.module.css";

import BackToTop from "react-back-to-top-button";
import { AiOutlineArrowUp } from "react-icons/ai";
import styles from "../css/footer.module.css";
import logoFacebook from "../images/Facebook_Icon_Logo.png";
import logoX from "../images/X_Icon_Logo.png";
import logoLinkedIn from "../images/LinkedIn_Icon_Logo.png";
import logoYouTube from "../images/YouTube_Icon_Logo.png";

export const Social = () => {
    return (
      <>
        <BackToTop showOnScrollUp showAt={100} speed={100} easing="easeOutSine">
          <span className={styles.BackToTop}>
            <AiOutlineArrowUp style={{ color: "#fff", fontSize: 30 }} />
          </span>
        </BackToTop>
        <div class={styles2.col_lg_4}>
        </div>
        <div class={styles2.col_lg_4} align="center"> <h5>Conecta con nosotros</h5>
          <div align="center">
              <div align="center">
                   <div class={styles2.col_lg_3}></div>
                  <div class={styles2.col_lg_2} title="Facebook:laboratorioRyM">
                      <a href="https://www.facebook.com/laboratorioRyM?mibextid=ZbWKwL" target="new">
                         <img src={logoFacebook} width="80px" alt="Facebook"/>
                      </a>
                  </div>
                  <div class={styles2.col_lg_2} title="X:RoboticaCic">
                      <a href="https://x.com/RoboticaCic?t=c735B91ZOQ-vuTFT45eafQ&s=09" target="new">
                         <img src={logoX} width="80px" alt="X - Twitter"/>
                      </a>
                  </div>
                  <div class={styles2.col_lg_2} title="LinkedIn:LaboratorioRyM">
                      <a href="https://www.linkedin.com/company/laboratorio-de-rob%C3%B3tica-y-mecatr%C3%B3nica-cic/posts/?feedView=all" target="new">
                         <img src={logoLinkedIn} width="80px" alt="LinkedIn"/>
                      </a>
                  </div>
                  <div class={styles2.col_lg_2} title="YouTube:LaboratorioRyM">
                      <a href="https://www.youtube.com/@LabRoboticaMecatronic" target="new">
                        <img src={logoYouTube} width="80px" alt="YouTube"/>
                      </a>
                  </div>
              </div>
          </div>
       </div>
      </>
    );
  };
