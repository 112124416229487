import salon from '../images/Infra/CIC-Laboratorio.jpg';
import impr1 from '../images/Infra/Impresora1.jpg';
import impr2 from '../images/Infra/Impresora2.png';
import server from '../images/Infra/servidores.jpg';
import banda from '../images/Infra/BandaTransportadora.jpg';
import brazo1 from '../images/Infra/BrazoMecanico.jpg';
import brazo2 from '../images/Infra/BrazoMecanico2.jpg'
import vehiculo1 from '../images/Infra/Vehiculo.jpeg';
import vehiculo2 from '../images/Infra/VehiculoGuia.jpg';

export const Infraestructura = () => {
    return (
      <>
        <h5 onLoad={window.scrollTo(0, 0)}>&nbsp;</h5>
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
            <h1>Infraestructura</h1>
            <hr class="red"/>
            <div align="center" className="col-lg-12">
                <image>
                    <img src={salon} width="80%" alt="Imagen de entrada a Laboratorio de robótica y mecatrónica-CIC"/>
                </image>
            </div>
            <div className="col-lg-12">
                <h3>Impresoras</h3>
                <hr class="red"/>
                <div className="col-lg-4">
                    <image>
                        <img src={impr1} width="90%" alt="Impresora"/>
                    </image>
                </div><div className="col-lg-8">
                    <p><h6>Impresora IM 430F</h6></p>
                    <p><strong>Características Técnicas</strong></p>
                    <ul>
                        <li>Resolución hasta 1200x1200dpi</li>
                        <li>Velocidad de impresión 43ppm tamaño A4</li>
                        <li>Memoria estándar 2GB Disco Duro 320GB</li>
                        <li>Velocidad de escaneo máximo 45 originales por minuto B/N o Color</li>
                        <li>Direcciones destino máximo 100 por trabajo/150 almacenadas</li>
                        <li>Escaneo a carpetas protocolo SMB, NCP o FTP</li>
                    </ul>
                </div>
                <div className="col-lg-12">&nbsp;</div>
                <div className="col-lg-4">
                    <image>
                        <img src={impr2} width="80%" alt="Impresora"/>
                    </image>
                </div>
                <div className="col-lg-8">
                   <p><h6>Kyocera TASKalfa 520i</h6></p>
                   <p><strong>Características Técnicas</strong></p>
                   <ul>
                    <li>Resolución 600 x 600 dpi</li>
                    <li>Velocidad de impresión 52ppm</li>
                    <li>2 GB de RAM y 160 GB de disco duro</li>
                    <li>Velocidad de escaneo 100 ipm B/N, 60 ipm color</li>
                    <li>Escaneo a PC, email, FTP (FTP vía SSL), TWAIN de red, SMB, escaneo a buzón, escaneo a USB </li>
                    <li>Data Security Kit (E) ISO 15408 (Common Criteria) con nivel de seguridad EAL3</li>
                   </ul>
                </div>
            </div>
            {/****************Servidores **********************/}
            <div className="col-lg-12">
                <h3>Servidores</h3>
                <hr class="red"/>
            </div>
            <div className="col-lg-4">
                <imagen>
                    <img src={server} width="80%" alt='Servidor'/>
                </imagen>
            </div>
            <div className="col-lg-8">
                <strong>Caracterísitcas Técnicas</strong>
                <h6>Servidor 1</h6>
                <ul>
                    <li>Ryzen 9 7950X</li>
                    <li>128 de RAM</li>
                    <li>NVIDIA GeForce RTX 4090</li>
                </ul>
                <h6>Servidor 2</h6>
                <ul>
                    <il>Intel I913900K</il>
                    <li>128 de RAM</li>
                    <li>NVIDIA GeForce RTX 4090</li>
                </ul>
            </div>
            {/**********************Robots************ */}
            <div className="col-lg-12">
                <h3>Banda Transportadora</h3>
                <hr class="red"/>
            </div>
            <div className="col-lg-8">
                <imagen>
                    <img src={banda} width="100%" alt='Banda Transportadora'/>
                </imagen>
            </div>
            {/**********************Robots************ */}
            <div className="col-lg-12">
                <h3>Brazos Mecánicos</h3>
                <hr class="red"/>
            </div>
            <div className="col-lg-6">
                <imagen>
                    <img src={brazo1} width="80%" alt='Brazo Mecánico'/>
                </imagen>
            </div>
            <div className="col-lg-6">
                <imagen>
                    <img src={brazo2} width="63%" alt='Brazo Mecánico'/>
                </imagen>
            </div>
            {/**********************Vehículos************ */}
            <div className="col-lg-12">
                <h3>Vehículos</h3>
                <hr class="red"/>
            </div>
            <div className="col-lg-6">
                <imagen>
                    <img src={vehiculo1} width="80%" alt='Vehículo robot'/>
                </imagen>
            </div>
            <div className="col-lg-6">
                <imagen>
                    <img src={vehiculo2} width="63%" alt='Vehículo robot'/>
                </imagen>
            </div>
        </div>
        <div className="col-lg-2"></div>
      </>
  );
}