import ListaAlumnos from "../components/ListAlumnos";
import { useLocation } from 'react-router-dom';

export const Alumnos = () => {

  return (
    <>
      <div  onLoad={window.scrollTo(0, 0)}>
        <h1 style={{ textAlign: "center", margin: 30 }}>Alumnos Semestre {useLocation().pathname.split('Alumnos')[1]}</h1>
        <div div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, justifyContent: 'center' }}>
          <ListaAlumnos Semestre={useLocation().pathname.split('Alumnos')[1]} />
        </div>
      </div>
    </>
  );
}