import styles2 from "../css/institucional.module.css";

import BackToTop from "react-back-to-top-button";
import { AiOutlineArrowUp } from "react-icons/ai";
import styles from "../css/footer.module.css";
import logoSep from "../images/pleca-educacion-footer.png";



export const Footer_ipn = () => {
  return (
    <>
       {/*
      <BackToTop showOnScrollUp showAt={100} speed={100} easing="easeOutSine">
        <span className={styles.BackToTop}>
          <AiOutlineArrowUp style={{ color: "#fff", fontSize: 30 }} />
        </span>
      </BackToTop>
      */}
      <div class={styles.footer_ipn}>
        <div>
            <div>
                <div class={styles2.col_lg_4}>
                    <img src={logoSep} class="img-fluid logo-educacion-footer" alt="Logo Secretaría de Educación Pública"/>
                </div>
                <div>
                    <p>INSTITUTO POLITÉCNICO NACIONAL</p>
                    <p>D.R. Instituto Politécnico Nacional (IPN). Av. Luis Enrique Erro S/N, Unidad Profesional Adolfo López Mateos, Zacatenco, Alcaldía Gustavo A. Madero, C.P. 07738, Ciudad de México. Conmutador: 55 57 29 60 00 / 55 57 29 63 00.</p>
                    <div>Esta página es una obra intelectual protegida por la Ley Federal del Derecho de Autor, puede ser reproducida con fines no lucrativos, siempre y cuando no se mutile, se cite la fuente completa y su dirección electrónica; su uso para otros fines, requiere autorización previa y por escrito de la Dirección General del Instituto.</div>
                </div>
            </div>
        </div>
     </div>
    </>
  );
};